// File#: _1_google-maps
// Usage: codyhouse.co/license
function initGoogleMap() {
	var contactMap = document.getElementsByClassName("js-google-maps");
	if (contactMap.length > 0) {
		for (var i = 0; i < contactMap.length; i++) {
			initContactMap(contactMap[i]);
		}
	}
}

function initContactMap(wrapper) {
	var coordinate = wrapper.getAttribute("data-coordinates").split(",");
	var map = new google.maps.Map(wrapper, {
		zoom: 15,
		center: { lat: Number(coordinate[0]), lng: Number(coordinate[1]) },
		styles: [
			{
				featureType: "administrative",
				elementType: "labels.text.fill",
				stylers: [
					{
						color: "#444444"
					}
				]
			},
			{
				featureType: "landscape",
				elementType: "all",
				stylers: [
					{
						color: "#f2f2f2"
					}
				]
			},
			{
				featureType: "poi",
				elementType: "all",
				stylers: [
					{
						visibility: "on"
					}
				]
			},
			{
				featureType: "road",
				elementType: "all",
				stylers: [
					{
						saturation: -100
					},
					{
						lightness: 45
					}
				]
			},
			{
				featureType: "road.highway",
				elementType: "all",
				stylers: [
					{
						visibility: "simplified"
					}
				]
			},
			{
				featureType: "road.arterial",
				elementType: "labels.icon",
				stylers: [
					{
						visibility: "off"
					}
				]
			},
			{
				featureType: "transit",
				elementType: "all",
				stylers: [
					{
						visibility: "off"
					}
				]
			},
			{
				featureType: "water",
				elementType: "all",
				stylers: [
					{
						color: "#46bcec"
					},
					{
						visibility: "on"
					}
				]
			}
		]
	});
	var marker = new google.maps.Marker({
		position: { lat: Number(coordinate[0]), lng: Number(coordinate[1]) },
		map: map
	});
}
